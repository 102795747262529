html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Bryndan Write";
  src: url("./fonts/BryndanWrite.ttf") format("truetype");
}

.navbarSocialsIndiv {
  /* margin-left: 20px; */
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
}

.bottomLandscape {
  width: 100%;
  height: auto;
}

.spacer {
  margin-top: 5%;
}
.spacer-triple {
  margin-top: 15%;
}
.spacer-faq {
  margin-top: 15%;
}
.spacer_to_faq {
  margin-top: 15%;
}

@media all and (max-width: 900px) {
  .spacer-triple {
    margin-top: 500px;
  }
  .spacer-faq {
    margin-top: 100px;
  }
  .spacer_to_faq {
    margin-top: -200px;
  }
}
@media all and (min-width: 900px) {
  .spacer-faq {
    margin-top: 20%;
  }
}

@media all and (min-width: 1200px) {
  .spacer-faq {
    margin-top: 15%;
  }
}

.spacer-top {
  margin-top: 6%;
}

.menuLink {
  /* color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */

  /* font-family: "COCOGOOSE";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;

  color: black; */
  display: block;
  margin-left: 60px;
  text-decoration: none;
}

.menuLinkMobile {
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
}
@media all and (min-width: 900px) and (max-width: 1000px) {
  .menuLink {
    margin-left: 40px;
  }
}
@media all and (min-width: 1000px) and (max-width: 1200px) {
  .menuLink {
    margin-left: 50px;
  }
}

@media all and (min-width: 1200px) {
  .menuLink {
    margin-left: 100px;
  }
}

.menuButton {
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;

  color: black;
}

.upArrow {
  width: 50px;
  transform: rotate(-110deg);
  position: fixed;
  right: 5px;
  bottom: 5vh;
  z-index: 10;
}

.upArrow:hover {
  cursor: pointer;
}

.upArrow:active {
  transform: rotate(-110deg) translateX(10px);
  /* transform: translateX(10px); */
}

.mainTitle {
  /* margin-top: -3vh; */
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 110px;
  line-height: 80px;

  color: #5599a5;
}

.subtitle {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 50px;
  text-align: center;

  color: #7db5be;
}

@media all and (max-width: 300px) {
  .mainTitle {
    font-size: 42px;
    margin-top: 3vh;
  }
  .subtitle {
    font-size: 30px;
    margin-top: -3vh;
  }
}

@media all and (min-width: 300px) and (max-width: 500px) {
  .mainTitle {
    font-size: 50px;
    margin-top: 3vh;
  }
  .subtitle {
    font-size: 36px;
    margin-top: -3vh;
  }
}

@media all and (min-width: 500px) and (max-width: 900px) {
  .mainTitle {
    font-size: 68px;
    margin-top: 0vh;
  }
  .subtitle {
    margin-top: -3vh;
    font-size: 42px;
  }
}

@media all and (min-width: 900px) and (max-width: 1200px) {
  .mainTitle {
    font-size: 88px;
  }
  .subtitle {
    font-size: 42px;
  }
}

.walletButtonText {
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;

  color: #343f41;
  margin-left: -10%;
  margin-bottom: 30px;
}

@media all and (min-width: 900px) and (max-width: 1200px) {
  .walletButtonText {
    font-size: 18px;
  }
}
/* @media all and (min-width: 1200px) {
  .walletButtonText {
    font-size: 18px;
  }
} */

.buttonText {
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;

  color: #343f41;
  margin-left: -10%;
  margin-bottom: auto;
}

@media all and (max-width: 900px) {
  .buttonText {
    font-size: 26px;
  }
}

.buttonFrame:hover {
  cursor: pointer;
}
.buttonFrame:active {
  transform: translateY(4px);
}

.mintButton {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  margin-top: 15%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 24px 36px;
  width: 280px;

  background: #fae2ac;
  box-shadow: 2px 6px 0px #8b6e54;
  border-radius: 50px;

  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;

  color: #343f41; */
}

.mintButton:hover {
  background-color: #f5ce73;
  cursor: pointer;
}
.mintButton:active {
  background-color: #ebc263;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.cover_div {
  position: relative;
  width: 100%;
}
.nuriUL {
  position: absolute;
  top: -280px;
  right: 60px;
  width: 150px;
  height: auto;
}
.nuriLL {
  position: absolute;
  top: 40px;
  right: 10px;
  width: 250px;
}
.nuriUR {
  position: absolute;
  top: -250px;
  right: 110px;
  width: 200px;
}
.nuriLR {
  position: absolute;
  top: 40px;
  right: 110px;
  width: 250px;
}

.nuriLREmpty {
  position: absolute;
  top: -20px;
  right: 110px;
  width: 250px;
  opacity: 0;
}

.nuriFalling {
  position: absolute;
  top: 100px;
  right: 105px;
  width: 250px;
  opacity: 0;
}

#nuri_explained {
  position: relative;
  width: 100%;
}
.cloud1 {
  position: absolute;
  top: -200px;
  left: -5vw;
  width: 85%;
  height: auto;
  max-width: 1200px;
}
.cloud2 {
  position: absolute;
  top: -200px;
  right: -5vw;
  width: 85%;
  height: auto;
  max-width: 1200px;
}

@media all and (max-width: 600px) {
  .cloud1 {
    width: 80%;
    min-width: 600px;
    margin-left: -30vw;
  }
  .cloud2 {
    width: 80%;
    min-width: 600px;
    margin-right: -50vw;
  }
}

@media all and (min-width: 600px) and (max-width: 900px) {
  .cloud1 {
    width: 80%;
    min-width: 600px;
    margin-left: -10vw;
  }
  .cloud2 {
    width: 80%;
    min-width: 600px;
    margin-right: -10vw;
  }
}

@media all and (min-width: 900px) and (max-width: 1200px) {
  .cloud1 {
    top: -80px;
  }
  .cloud2 {
    top: -80px;
  }
}

.cloudParagraph {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 55px;
  text-align: center;

  color: #2c737e;
}

.cloud_emphasis {
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
}

.nuriLanding {
  position: absolute;
  top: 150px;
  right: 10.3vw;
  width: 19%;
  height: auto;
  /* opacity: 0; */
}

.nuriLanded {
  position: absolute;
  display: none;
  width: 240px;
}

/*Mobile Screen*/
@media all and (min-width: 0px) and (max-width: 600px) {
  .nuriUL {
    display: none;
  }
  .nuriUR {
    display: none;
  }
  .nuriLL {
    top: 200px;
    left: 0px;
    width: 200px;
  }
  .nuriLR {
    left: 0px;
  }
  .nuriLREmpty {
    top: -45px;
    left: 0px;
    width: 260px;
  }
  .nuriFalling {
    top: 60px;
    left: 0px;
    width: 260px;
  }
  .nuriLanding {
    top: 140px;
    right: 0px;
    width: 240px;
  }
  .nuriLanded {
    opacity: 0;
    display: block;
    width: 240px;
    top: 75px;
    left: 50%;
  }
}
@media all and (min-width: 600px) and (max-width: 900px) {
  .nuriUL {
    display: none;
  }
  .nuriUR {
    display: none;
    /* top: -40px;
    left: 100px;
    width: 180px; */
  }
  .nuriLL {
    top: 200px;
    left: 0px;
    width: 200px;
  }

  .nuriLR {
    top: 20px;
    left: 120px;
    width: 260px;
  }
  .nuriLREmpty {
    top: -45px;
    left: 120px;
    width: 260px;
  }
  .nuriFalling {
    top: 60px;
    left: 120px;
    /* width: 400px; */
    width: 260px;
  }
  .nuriLanding {
    top: 48px;
    right: 80px;
    /* width: 400px; */
    width: 240px;
  }
  .nuriLanded {
    opacity: 0;
    display: block;
    width: 240px;
    top: 100px;
    left: 58%;
  }
}

@media all and (min-width: 1200px) {
  .nuriUL {
    top: -280px;
    right: -40px;
    width: 200px;
  }
  .nuriLL {
    top: 100px;
    right: -50px;
    width: 350px;
  }
  .nuriUR {
    top: -300px;
    left: -50px;
    width: 250px;
  }
  .nuriLR {
    top: 40px;
    left: -80px;
    /* width: 400px; */
    width: 85%;
  }
  .nuriLREmpty {
    top: -30px;
    left: -80px;
    /* width: 400px; */
    width: 85%;
    opacity: 0;
  }
  .nuriFalling {
    top: 100px;
    left: -80px;
    /* width: 400px; */
    width: 85%;
  }

  .nuriLanding {
    position: absolute;
    top: 200px;
    right: 10.3vw;
    width: 19%;
    height: auto;
    /* opacity: 0; */
  }
}

/** Nuri Family */

.intro_image {
  /* margin-top: 30%; */
  width: 40%;
}

.introParagraph {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 4vh;
  text-align: center;

  color: #343f41;
}
#paragraph1 {
  margin-top: 25%;
}

.introEmphasis {
  font-family: "Bryndan Write";
  font-style: bold;
  font-size: 26px;
}

@media all and (min-width: 1200px) {
  .introEmphasis {
    font-size: 32px;
  }
}
@media all and (min-width: 1536px) {
  .introEmphasis {
    font-size: 36px;
  }
}

.introHighlight {
  font-weight: 600;
  font-style: italic;
}

.whoAreWeTitle {
  text-align: center;
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 47px;

  color: #343f41;
}

.whoAreWeContainer {
  position: relative;
  text-align: center;
}

.whoAreWeCloud {
  width: 110vw;
  max-width: 1600px;
  /* opacity: 0; */
}
.whoAreWeCloudEmpty {
  display: none;
  width: 110vw;
}

.nuriSample {
  width: 70%;
  border-radius: 10px;
  border: 5px solid #343f41;
}

#paragraph2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media all and (max-width: 900px) {
  .whoAreWeCloud {
    display: none;
    width: 110vw;
  }
  .whoAreWeCloudEmpty {
    display: block;
    width: 200vw;
    min-width: 800px;
    max-width: 1000px;
  }
  .cloudParagraph {
    font-size: 24px;
    line-height: 32px;
  }
  .whoAreWeTitle {
    font-size: 32px;
  }
  .introParagraph {
    font-size: 18px;
    line-height: 24px;
  }
  .introEmphasis {
    font-size: 18px;
  }
  #paragraph2 {
    width: 80vw;
    top: 50%;
  }
  .nuriSample {
    width: 80%;
  }
}
@media all and (max-width: 600px) {
  .cloudParagraph {
    font-size: 18px;
    line-height: 32px;
  }
}
@media all and (min-width: 0px) and (max-width: 420px) {
  #paragraph2 {
    top: 55%;
  }
}
@media all and (min-width: 900px) {
  .introParagraph {
    font-size: 26px;
    line-height: 36px;
  }
  .cloudParagraph {
    font-size: 24px;
  }
}
@media all and (min-width: 1200px) {
  .introParagraph {
    font-size: 28px;
    line-height: 45px;
  }
  .cloudParagraph {
    font-size: 30px;
  }
  .whoAreWeTitle {
    font-size: 60px;
  }
}
@media all and (min-width: 1536px) {
  .introParagraph {
    font-size: 36px;
    line-height: 55px;
  }
  .cloudParagraph {
    font-size: 40px;
  }
  .whoAreWeTitle {
    font-size: 60px;
  }
}

/** Affirmation App */
.smallCloud1 {
  position: relative;
  right: 5vw;
  margin-left: auto;
  width: 20vw;
  margin-top: 0vh;
  margin-bottom: -15vh;
}

.affirmationAppContainer {
  margin-top: -5vh;
  position: relative;
  text-align: center;
}

.affirmationAppCloud {
  width: 100vw;
  max-width: 1500px;
}

.affirmationTitle {
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 55px;
  line-height: 68px;

  color: #343f41;
  text-align: left;

  margin-top: 20px;
}

.affirmationP {
  margin-top: -35px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  text-align: left;

  color: #32393a;
}
.affirmationEmphasis {
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;

  color: #343f41;
}

.affirmationAppImg {
  width: 70%;
  max-width: 400px;
  padding-left: 50px;
  margin-top: -50px;
}
@media all and (max-width: 900px) {
  .affirmationAppContainer {
    margin-top: 350px;
  }
  .affirmationAppCloud {
    width: 220vw;
    max-width: 1600px;
    margin-left: 70vw;
  }
  .affirmationTitle {
    font-size: 32px;
  }
  .affirmationP {
    font-size: 18px;
  }
  .affirmationEmphasis {
    font-size: 21px;
  }
  .affirmationAppImg {
    width: 70%;
    max-width: 350px;
    padding-left: 50px;
    margin-top: 40px;
  }
}

@media all and (min-width: 0px) and (max-width: 500px) {
  .affirmationP {
    margin-top: -35px;
  }
}

@media all and (min-width: 900px) and (max-width: 1200px) {
  .affirmationTitle {
    margin-top: -50px;
  }
  .affirmationP {
    font-size: 21px;
    margin-top: -35px;
  }
  .affirmationAppCloud {
    width: 100vw;
    min-width: 1000px;
  }
  .affirmationAppImg {
    margin-top: -75px;
    width: 110%;
    max-width: 400px;
    padding-left: 50px;
  }
}

@media all and (min-width: 1200px) {
  .affirmationP {
    font-size: 24px;
    line-height: 42px;
  }

  .affirmationEmphasis {
    font-size: 28px;
  }
}

/** Pawprint */

.pawprintTitle {
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 68px;
  text-align: center;

  color: #343f41;
}
.subtitle2 {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  text-align: center;

  color: #343f41;
}

.pawprintSubtitle {
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;

  color: #343f41;
}
.pawprintP {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  /* font-size: min(1.4vw, 24px); */
  font-size: 18px;
  line-height: 33px;
  margin-left: 28px;
  color: #32393a;
}

.pawprintImage1 {
  width: 18%;
}
.pawprintImage2 {
  width: 18%;
}
.pawprintImage3 {
  width: 18%;
}
.pawprintImage4 {
  width: 18%;
}

.pawprintCloudContainer {
  position: relative;
}

.pawprintCloud1 {
  position: relative;
  width: 125%;
  margin-top: -200px;
  min-width: 700px;
}
.pawprintCloud2 {
  position: relative;
  margin-top: -50px;
  width: 125%;
  z-index: 1;
  min-width: 700px;
}
.pawprintCloud3 {
  position: relative;
  margin-top: -320px;
  width: 125%;
  z-index: 2;
  min-width: 700px;
}
.pawprintCloud4 {
  position: relative;
  left: -10vw;
  margin-top: -200px;
  width: 125%;
  z-index: 3;
  min-width: 900px;
  max-width: 1400px;
}

/* Mobile */
@media all and (max-width: 900px) {
  .pawprintTitle {
    font-size: 32px;
  }
  .pawprintSubtitle {
    font-size: 24px;
  }
  .pawprintP {
    font-size: 16px;
  }

  .pawprintCloud1 {
    width: 100vw;
    min-width: 600px;
    max-width: 700px;
    margin-top: -120px;
    margin-left: 100px;
  }
  .pawprintCloud2 {
    width: 110vw;
    min-width: 650px;
    max-width: 750px;
    margin-top: -330px;
    margin-left: 100px;
  }
  .pawprintCloud3 {
    width: 100vw;
    min-width: 600px;
    max-width: 700px;
    margin-top: -330px;
    margin-left: 150px;
  }
  .pawprintCloud4 {
    width: 110vw;
    min-width: 750px;
    max-width: 1200px;
    margin-top: -180px;
    margin-left: 120px;
  }
}

@media all and (min-width: 600px) and (max-width: 900px) {
  .pawprintSubtitle {
    font-size: 28px;
  }
  .pawprintP {
    font-size: 21px;
  }
  .pawprintCloud2 {
    margin-top: -340px;
  }
  .pawprintCloud3 {
    margin-top: -350px;
  }
  .pawprintCloud4 {
    margin-top: -180px;
    width: 100vw;
    max-width: 1100px;
  }
}

@media all and (min-width: 750px) and (max-width: 900px) {
  .pawprintCloud1 {
    margin-left: 180px;
  }
  .pawprintCloud2 {
    margin-top: -400px;
    margin-left: 200px;
  }
  .pawprintCloud3 {
    margin-left: 220px;
    margin-top: -400px;
  }
  .pawprintCloud4 {
    margin-left: 220px;
    margin-top: -200px;
    max-width: 1000px;
  }
}

@media all and (min-width: 1200px) {
  .pawprintCloud1 {
    width: 125%;
    margin-top: -200px;
  }
  .pawprintCloud2 {
    margin-top: 0px;
    right: 8vw;
    width: 125%;
  }
  .pawprintCloud3 {
    margin-top: -425px;
    width: 125%;
  }
  .pawprintCloud4 {
    margin-top: -200px;
    right: 8vw;
    width: 125%;
    max-width: 1000px;
  }
  .pawprintP {
    font-size: 21px;
  }
}
@media all and (min-width: 1546px) {
  .pawprintCloud1 {
    width: 125%;
    margin-top: -280px;
    max-width: 1000px;
  }
  .pawprintCloud2 {
    margin-top: -50px;
    right: 8vw;
    width: 125%;
    max-width: 1000px;
  }
  .pawprintCloud3 {
    margin-top: -500px;
    width: 125%;
    max-width: 1000px;
  }
  .pawprintCloud4 {
    margin-top: -250px;
    right: 8vw;
    width: 150%;
    max-width: 1000px;
  }
  .pawprintP {
    font-size: 24px;
  }
}

/** FAQ */
#faq {
  margin-top: -25vh;
  width: 60%;
}

.faq_cloud {
  width: 100%;
  margin-top: -5vh;
  max-width: 700px;
}
.faq_cloud:hover {
  cursor: pointer;
}

.faqTitle {
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 68px;
  text-align: center;

  color: #343f41;
}

.faqSubtitle {
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  color: #343f41;
  text-align: center;
  flex-grow: 1;
}

.FAQText p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-align: left;
  color: #343f41;
  padding-left: 5vw;
  padding-right: 5vw;
}
.faqEmphasis {
  font-family: "Bryndan Write";
  font-style: bold;
  font-size: 28px;
}

@media all and (max-width: 900px) {
  #faq {
    margin-top: 200px;
    width: 95%;
  }
  .faq_cloud {
    width: 100%;
  }
  .faq_cloud:hover {
    cursor: pointer;
  }

  .faqTitle {
    font-size: 32px;
  }

  .faqSubtitle {
    font-size: 24px;
  }

  .FAQText p {
    font-size: 18px;
  }
  .faqEmphasis {
    font-size: 21px;
  }
}

.spacer2 {
  margin-top: 3%;
}

.nuri_image {
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10%;
}

/* Team */

.teamTitle {
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 68px;
  text-align: center;

  color: #343f41;
}

.teamName {
  margin-top: 10px;
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  text-align: center;

  color: #343f41;
}

.teamBio {
  margin-top: -20px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 33px;
  text-align: center;
  /* identical to box height */

  color: #32393a;
}

.bioEmphasis {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 33px;
  text-align: left;
  color: #32393a;
}

.bioImage {
  width: 50%;
  min-width: 120px;
  border-radius: 10px;
  border: 5px solid #343f41;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.bioImage:hover {
  cursor: pointer;
}

.teamNameInCloud {
  margin-top: 10px;
  font-family: "Bryndan Write";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  text-align: left;

  color: #343f41;
}

.teamBioInCloud {
  margin-top: -20px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 33px;
  text-align: left;
  /* identical to box height */

  color: #32393a;
}

.bioImageInCloud {
  width: 30%;
  max-width: 200px;

  border-radius: 10px;
  border: 5px solid #343f41;
  text-align: left;
  display: block;
  position: relative;
}

.bioLabel {
  display: flex;
}

.bioLink {
  color: #5599a5;
}

.bioCloud {
  min-width: 800px;
  min-height: 768px;
  height: 95vh;
  /* width: 80%; */
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.bioSocial1 {
  margin-left: 20px;
  margin-top: 12px;
}
.bioSocial {
  margin-left: 10px;
  margin-top: 12px;
}

/* @media all and (max-height: 750px) {
  .bioCloudContent {
    zoom: 0.7;
    -moz-transform: scale(0.7);
    max-width: 400px;
    left: 60%;
  }
} */

.bioCloudContainer {
  position: relative;
}

@media all and (max-width: 900px) {
  .teamTitle {
    font-size: 32px;
  }
  .teamName {
    font-size: 24px;
  }
  .teamBio {
    font-size: 18px;
  }
  .bioCloud {
    margin-left: -20vw;
  }
  .teamNameInCloud {
    font-size: 24px;
  }
  .teamBioInCloud {
    font-size: 16px;
    line-height: 26px;
  }
  .bioEmphasis {
    font-size: 16px;
    line-height: 26px;
  }
}
@media all and (min-width: 0px) and (max-width: 600px) {
  .bioCloud {
    margin-left: -270px;
  }
}
@media all and (min-width: 600px) and (max-width: 900px) {
  .bioCloud {
    margin-left: -200px;
  }
}
@media all and (min-width: 800px) and (max-width: 900px) {
  .bioCloud {
    margin-left: -150px;
  }
}

@media all and (min-width: 900px) and (max-width: 1050px) {
  .bioCloud {
    margin-left: -10vw;
  }
}
@media all and (min-width: 1050px) and (max-width: 1200px) {
  .bioCloud {
    margin-left: -3vw;
  }
}

.popup-overlay.bioPopup-overlay {
  overflow-y: scroll;
}

.popup-content.bioPopup-content {
  margin: auto;
  background: transparent;
  width: 70vw;
  max-width: 900px;
  padding: 5px;
  border: none;
}

/** Marquee Banner */
.marqueeImage {
  width: 10%;
}

.marquee {
  width: 100%;
}

.marqueeTrain {
  width: 100%;
}

/** Pawprint */
.paw {
  width: 65%;
}
.paw80 {
  width: 65%;
  transform: rotate(80deg);
}
.paw40 {
  width: 65%;
  transform: rotate(40deg);
}

.paw-40 {
  width: 65%;
  transform: rotate(-40deg);
}

.pawImageContainer {
  width: 100%;
  position: relative;
}

.pawText {
  position: relative;
  width: 80%;
  margin-top: -35%;

  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  text-align: center;

  color: #343f41;
}

.pawTextHigher {
  position: relative;
  width: 80%;
  margin-top: -50%;

  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  text-align: center;

  color: #343f41;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

.flip-card:hover .flip-card-inner {
  transform: rotateX(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  /* background-color: #bbb;
  color: black; */
}

.flip-card-back {
  /* background-color: #2980b9; */
  /* color: white; */
  transform: rotateX(180deg);
}

/* Privacy Policy */
.privacyTitle {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 42px;
  text-align: center;

  color: #343f41;
}
.privacySubtitle {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 42px;
  text-align: left;

  color: #343f41;
}
.privacyP {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  text-align: left;

  color: #343f41;
}
